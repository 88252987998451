<template>
  <div class="my-5 mr-5">
    <div v-if="billings.length">
      <span>
        You have already added a billing profile.<br />
        To manage your billing profiles
      </span>
      <bid-link
        :router-link="true"
        href-link="account:billing:profiles"
      >
        <template #text> click here </template>
        <template #icon>
          <ic-link />
        </template>
      </bid-link>
      <div class="flex flex-wrap mt-5 gap-3">
        <the-button
          @click.stop="$emit('updateStep', { step, index, incremental: -1 })"
          inner-class="w-40"
          secondary
        >
          Back
        </the-button>
        <the-button
          @click.stop="$emit('updateStep', { step, index })"
          inner-class="w-40"
          primary
        >
          Next
        </the-button>
      </div>
    </div>
    <account-billing-form
      v-else
      @saved="onBillingSaved({ step, index })"
    >
      <template #action-btn="{ loading, save, savingBilling }">
        <div class="flex flex-wrap mt-5 gap-3">
          <the-button
            @click.stop="$emit('updateStep', { step, index, incremental: -1 })"
            inner-class="w-40"
            secondary
          >
            Back
          </the-button>
          <the-button
            v-if="!step.completed"
            @click.stop="save"
            :disabled="loading || savingBilling"
            id="billing_next"
            inner-class="w-40"
            primary
          >
            Complete
            <template #icon>
              <ic-check :size="20" />
            </template>
          </the-button>
          <the-button
            v-else
            @click.stop="$emit('updateStep', { step, index })"
            inner-class="w-40"
            primary
          >
            Next
          </the-button>
        </div>
      </template>
    </account-billing-form>
  </div>
</template>

<script>
import AccountBillingForm from '@/components/account/BillingForm'
import IcCheck from 'vue-material-design-icons/Check'
import { mapGetters } from 'vuex'
import BidLink from '@/components/base/BidLink'
import IcLink from 'vue-material-design-icons/OpenInNew'

export default {
  name: 'BillingInformation',
  components: { BidLink, AccountBillingForm, IcCheck, IcLink },
  props: {
    step: {
      type: Object,
    },
    index: {
      type: Number,
    },
    checkIfLastStep: {
      type: Boolean,
    },
    allTaskCompleted: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      billings: 'billing/getAccountBillings',
    }),
  },
  methods: {
    onBillingSaved({ step, index }) {
      this.$gtm.trackEvent({
        event: 'onboarding_billing',
        event_source: 'onboarding',
        project: 'console',
      })
      this.$emit('updateStep', { step, index })
    },
  },
}
</script>
